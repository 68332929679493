import Vue from 'vue'
import Vuex from 'vuex'
import { getGoodsAttr } from "@/api/goodsCategory"

Vue.use(Vuex)

export default {
  state: {
    goods: {
        goodsName: "",
        goodsPrice: 0.0,
        goodsWeight: 0,
        goodsNumber: 0,
        pics: [],
        goodsDesc: undefined,
        // goodsDesc: "<p>测试用</p>",
        goodsCate: [],
        dynamicParam: [],
        attrs: [],
        goodsSmallLogo: undefined,
      },
    goodsAttr:{}
      
  },
  mutations: {
    getGoods(state,payload) {
      state.goods = payload;
    },
    getGoodsAttrMut(state,payload) {
      state.goodsAttr = payload;
    },
    getGoodsSmallLogo(state,payload) {
      state.goods.goodsSmallLogo = payload;
    },
    getPics(state,payload) {
      state.goods.pics = payload;
    },
    getDynamicParam(state,payload) {
      state.goods.dynamicParam = payload;
    },
    getGoodsCate(state) {
      state.goods.goodsCate = state.goods.goodsCate.join("_");
    },
    getGoodsDesc(state,payload) {
      state.goods.goodsDesc = payload;
    }
  },
  getters: {
  },
  actions: {
    async getGoodsAttrActions(context) {
      let rs = await getGoodsAttr({"catId":context.state.goods.goodsCate[2],"attrSel":2});
      context.commit("getGoodsAttrMut",rs);
    }
  }
}