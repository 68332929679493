import requestObj  from "@/utils/request";

// export const Login = (userName,userPwd) => {
//     return requestObj.get("/users?userName="+userName+"&password="+userPwd);
// }
export const Login = (userName,userPwd) => {
    return requestObj.post("/users/login",
        {'username' : userName, 'passwd' : userPwd}
    );
}
export const userList = (obj) => {
    return requestObj.post("/users/listusers",obj);
}
export const addUsers = (obj) => {
    return requestObj.post("/users/addusers",obj);
}
export const updUsers = (obj) => {
    return requestObj.post("/users/updusers",obj);
}
export const deleteUsers = (obj) => {
    return requestObj.post("/users/delusers",obj);
}