
import axios from 'axios';
import config from "@/utils/config.js";
import { Message } from "element-ui";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
    showSpinner:false
})

const requestObj = axios.create({
    // baseURL: 'http://localhost:3000',
    // baseURL: 'http://lqgapidemo.quechao.xyz',
    baseURL: config.apiHost,
    timeout: 5000
})

//请求拦截器，所有发出的请求发送之前都要经过这里
requestObj.interceptors.request.use(
    (config)=> {
        config.headers.Authorization = "Bearer "  + sessionStorage.getItem("jwttoken");
        NProgress.start();
        return config;
    },
    error => {
        Message.error("请求拦截器出现异常："+ error);
        return Promise.reject(error); 
    }
)

//响应拦截器，所有接口返回的消息都会先进入这个拦截器
requestObj.interceptors.response.use(
    (response) => {
        // Message.success("响应到达");
        NProgress.done();
        return response.data;
    },
    (error) => {
        Message.error("响应拦截器出现异常："+error);
        return Promise.reject(error);
    }
)

export default requestObj;