import Vue from 'vue'
import Vuex from 'vuex'
import { userList } from "@/api/users";

Vue.use(Vuex)

export default {
  state: {
    users: {},
    isCollapse: false,
    stateUserList : []
  },
  mutations: {
    modifyUsers(state,playload) {
      state.users = playload;
    },
    getUserListmut(state, playload) {
      state.stateUserList = playload;
    },
    modifyCollapse(state,payload) {
      state.isCollapse = payload;
    }
  },
  getters: {
    getUsers(state) {
      return state.users;
    }
  },
  actions: {
    async getUserList(context,payload) {
      let data = await userList(payload);
      context.commit('getUserListmut',data);
    }
  }
}
