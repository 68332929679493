import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import("../views/login.vue")
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/goodsList',
        name: 'GoodsList',
        component: () => import(/* webpackChunkName: "Manager" */ '../views/homemenu/goods/goodsList.vue')
      },
      {
        path: '/addGoods',
        name: 'AddGoods',
        component: () => import(/* webpackChunkName: "Manager" */ '../views/homemenu/goods/addGoods.vue')
      },
      {
        path: '/manager',
        name: 'Manager',
        component: () => import(/* webpackChunkName: "Manager" */ '../views/homemenu/system/manager.vue')
      },
      
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/mytest',
    name: 'MyTest',
    component: () => import('@/views/MyTest.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "about" */ '../views/homemenu/logout.vue')
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode:'history',
  routes,
})

const VueRouterPush = VueRouter.prototype.push
 VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to,from,next) => {
  console.log("测试1："+sessionStorage.getItem("jwttoken"));
//   if(to.name != "Login" && sessionStorage.getItem("jwttoken") == null) {
//     next('login');
//   }
  next();
})


export default router
