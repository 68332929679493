import Vue from 'vue'
import {
    Button,
    Form, 
    Input, 
    FormItem, 
    Row, 
    Col, 
    Message, 
    Container, 
    Aside, 
    Menu, 
    Submenu, 
    MenuItemGroup, 
    MenuItem,
    Header,
    Main,
    Table,
    TableColumn,
    Select,
    Option,
    Pagination,
    Switch,
    Dialog,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Card,
    Alert,
    Steps,
    Step,
    Tabs,
    TabPane,
    Cascader,
    CheckboxGroup,
    Checkbox,
    Upload,
    Radio
} from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(Input)
Vue.use(FormItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Header)
Vue.use(Main)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Switch)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Card)
Vue.use(Alert)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Cascader)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Upload)
Vue.use(Radio)



Vue.prototype.$message = Message